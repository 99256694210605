'use client'
import React from 'react'
import Link from "next/link"
import Image from "next/image"
import { useState } from 'react';


export default function Navbar() {
  const[menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };


  return (
    <nav className='navbar'>
        <div className='flex aling-center'>
            <Link href="/" className=''>
                    <Image src="/images/logo.png" alt="Cleanit Logo" width={150} height={75} />
            </Link>
        </div>
        <div className={`navbar-toggle ${menuOpen ? 'is-active' : ''}`} id="mobile-menu" onClick={toggleMenu}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
        <ul className={`navbar-links ${menuOpen ? 'menu-open' : ''}`}>
        <li>
          <Link href="#gallery" title="Sneakers Helsinki" className='navbar-link' onClick={toggleMenu}>Galleria</Link>
        </li>
        <li>
          <Link href="#services" title="kenkien pesu" className='navbar-link' onClick={toggleMenu}>Palvelut</Link>
        </li>
        <li>
          <Link href="#about" title="Kenkäpesula pääkaupunkiseudulla" className='navbar-link' onClick={toggleMenu}>Meistä</Link>
        </li>
        <li>
          <Link href="#contact" title="näin teet kengistäsi puhtaat" className='button' onClick={toggleMenu}>Ota Yhteyttä</Link>
        </li>
      </ul>
    </nav>
  )
}
