import { Checkbox, extendTheme } from '@chakra-ui/react'
import { checkboxTheme } from './formTheme'

const theme = extendTheme({
    components: {
    },
    breakpoints: {
        base: '0px',
        sm: '479px',
        md: '967px',
        lg: '1091px',
        xl: '1290px',
    },
});

export default theme;